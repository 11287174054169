import React from 'react';

const IconLoader = () => (
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    zoomAndPan="magnify"
    viewBox="0 0 375 374.999991"
    height="500"
    preserveAspectRatio="xMidYMid meet"
    version="1.0">
    <defs>
      <g />
      <clipPath id="bd1f0a69be">
        <path
          d="M 0 147.3125 L 375 147.3125 L 375 228.148438 L 0 228.148438 Z M 0 147.3125 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <g fill="#64ffda" fillOpacity="1" id="B" transform="translate(11.000000, 5.000000)">
      <g transform="translate(13.981463, 320.852301)">
        <g>
          <path d="M 126.695312 -135.46875 C 147.355469 -152.621094 159.441406 -178.152344 159.441406 -211.289062 L 159.441406 -261.382812 C 159.441406 -264.890625 157.6875 -269.765625 152.425781 -269.765625 L 116.367188 -269.765625 C 111.882812 -269.765625 107.398438 -266.644531 107.398438 -260.800781 L 107.398438 -217.136719 C 107.398438 -184.195312 98.042969 -161.390625 65.882812 -158.664062 L 65.882812 -262.164062 C 65.882812 -266.839844 63.933594 -269.765625 59.449219 -269.765625 L 20.660156 -269.765625 C 16.371094 -269.765625 14.035156 -266.839844 14.035156 -262.164062 L 14.035156 -7.40625 C 14.035156 -2.730469 16.179688 0 20.660156 0 L 59.644531 0 C 64.128906 0 65.882812 -2.730469 65.882812 -7.40625 L 65.882812 -109.738281 C 100.1875 -108.179688 110.128906 -84.984375 110.128906 -51.261719 L 110.128906 -8.769531 C 110.128906 -2.921875 114.417969 0 118.898438 0 L 155.152344 0 C 160.222656 0 162.171875 -4.679688 162.171875 -8.1875 L 162.171875 -57.304688 C 162.171875 -91.804688 149.113281 -118.316406 126.695312 -135.46875 Z M 126.695312 -135.46875 " />
        </g>
      </g>
    </g>
    <g fill="#64ffda" fillOpacity="1" id="B" transform="translate(11.000000, 5.000000)">
      <g transform="translate(205.078205, 320.852301)">
        <g>
          <path d="M 98.042969 -191.601562 C 98.042969 -186.144531 98.824219 -184.195312 103.695312 -184.195312 L 138.585938 -184.195312 C 144.824219 -184.195312 146.1875 -186.144531 146.1875 -191.601562 L 146.1875 -202.714844 C 146.1875 -245.789062 117.339844 -273.859375 76.992188 -273.859375 C 49.703125 -273.859375 7.796875 -254.5625 7.796875 -203.882812 C 7.796875 -159.246094 26.117188 -146.382812 66.855469 -113.246094 C 90.832031 -93.753906 100.96875 -84.984375 100.96875 -66.078125 C 100.96875 -51.847656 91.027344 -42.882812 78.746094 -42.882812 C 68.804688 -42.882812 56.527344 -49.117188 56.527344 -68.609375 L 56.527344 -77.382812 C 56.527344 -83.230469 55.355469 -85.375 49.898438 -85.375 L 14.425781 -85.375 C 8.964844 -85.375 7.796875 -82.644531 7.796875 -76.019531 L 7.796875 -65.6875 C 7.796875 -29.042969 31.382812 4.289062 78.355469 4.289062 C 121.433594 4.289062 150.28125 -26.898438 150.28125 -63.347656 C 150.28125 -112.660156 121.238281 -133.90625 84.789062 -162.171875 C 67.441406 -175.621094 55.941406 -184 55.941406 -202.519531 C 55.941406 -220.257812 67.246094 -227.078125 76.992188 -227.078125 C 91.027344 -227.078125 98.042969 -215.96875 98.042969 -202.519531 Z M 98.042969 -191.601562 " />
        </g>
      </g>
    </g>
    <g clipPath="url(#bd1f0a69be)">
      <path
        fill="#0a192f"
        d="M -55.691406 147.3125 L 430.804688 147.3125 L 430.804688 228.148438 L -55.691406 228.148438 L -55.691406 147.3125 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
    <g fill="#64ffda" fillOpacity="1">
      <g transform="translate(23.234198, 200.708826)">
        <g>
          <path d="M 11.398438 -12.1875 C 13.257812 -13.734375 14.347656 -16.03125 14.347656 -19.011719 L 14.347656 -23.519531 C 14.347656 -23.835938 14.1875 -24.273438 13.714844 -24.273438 L 10.46875 -24.273438 C 10.066406 -24.273438 9.664062 -23.992188 9.664062 -23.464844 L 9.664062 -19.539062 C 9.664062 -16.574219 8.820312 -14.523438 5.929688 -14.277344 L 5.929688 -23.589844 C 5.929688 -24.011719 5.753906 -24.273438 5.347656 -24.273438 L 1.859375 -24.273438 C 1.472656 -24.273438 1.261719 -24.011719 1.261719 -23.589844 L 1.261719 -0.667969 C 1.261719 -0.246094 1.457031 0 1.859375 0 L 5.367188 0 C 5.769531 0 5.929688 -0.246094 5.929688 -0.667969 L 5.929688 -9.875 C 9.015625 -9.734375 9.910156 -7.648438 9.910156 -4.613281 L 9.910156 -0.789062 C 9.910156 -0.261719 10.296875 0 10.699219 0 L 13.960938 0 C 14.417969 0 14.59375 -0.421875 14.59375 -0.738281 L 14.59375 -5.15625 C 14.59375 -8.261719 13.417969 -10.644531 11.398438 -12.1875 Z M 11.398438 -12.1875 " />
        </g>
      </g>
    </g>
    <g fill="#64ffda" fillOpacity="1">
      <g transform="translate(57.129411, 200.708826)">
        <g>
          <path d="M 5.789062 -18.308594 C 5.789062 -19.414062 6.472656 -20.414062 7.664062 -20.414062 C 8.839844 -20.414062 9.523438 -19.414062 9.523438 -18.308594 L 9.523438 -9.875 L 5.789062 -9.875 Z M 14.207031 -18.574219 C 14.207031 -21.941406 11.15625 -24.660156 7.648438 -24.660156 C 4.140625 -24.660156 1.105469 -21.941406 1.105469 -18.574219 L 1.105469 -0.683594 C 1.105469 -0.316406 1.296875 0 1.5625 0 L 5.261719 0 C 5.683594 0 5.789062 -0.296875 5.789062 -0.683594 L 5.789062 -5.789062 L 9.523438 -5.789062 L 9.523438 -0.703125 C 9.523438 -0.332031 9.734375 0 10.085938 0 L 13.644531 0 C 14.046875 0 14.207031 -0.351562 14.207031 -0.703125 Z M 14.207031 -18.574219 " />
        </g>
      </g>
    </g>
    <g fill="#64ffda" fillOpacity="1">
      <g transform="translate(90.936973, 200.708826)">
        <g>
          <path d="M 11.855469 0 C 12.171875 0 12.347656 -0.210938 12.347656 -0.632812 L 12.347656 -3.628906 C 12.347656 -4.050781 12.171875 -4.226562 11.855469 -4.226562 L 5.929688 -4.226562 L 5.929688 -23.589844 C 5.929688 -24.011719 5.753906 -24.273438 5.347656 -24.273438 L 1.839844 -24.273438 C 1.457031 -24.273438 1.261719 -24.011719 1.261719 -23.589844 L 1.261719 -0.667969 C 1.261719 -0.246094 1.457031 0 1.859375 0 Z M 11.855469 0 " />
        </g>
      </g>
    </g>
    <g fill="#64ffda" fillOpacity="1">
      <g transform="translate(122.167381, 200.708826)">
        <g>
          <path d="M 9.699219 -12.328125 C 9.699219 -11.136719 8.910156 -10.242188 7.699219 -10.242188 L 5.929688 -10.242188 L 5.929688 -20.011719 L 7.683594 -20.011719 C 8.890625 -20.011719 9.699219 -19.152344 9.699219 -17.890625 Z M 1.261719 -0.667969 C 1.261719 -0.246094 1.457031 0 1.859375 0 L 5.347656 0 C 5.753906 0 5.929688 -0.246094 5.929688 -0.667969 L 5.929688 -6.015625 L 8.050781 -6.015625 C 11.664062 -6.015625 14.382812 -8.839844 14.382812 -12.363281 L 14.382812 -17.976562 C 14.382812 -21.503906 11.664062 -24.273438 8.050781 -24.273438 L 1.859375 -24.273438 C 1.492188 -24.273438 1.261719 -24.011719 1.261719 -23.589844 Z M 1.261719 -0.667969 " />
        </g>
      </g>
    </g>
    <g fill="#64ffda" fillOpacity="1">
      <g transform="translate(155.448995, 200.708826)">
        <g>
          <path d="M 5.929688 -23.589844 C 5.929688 -24.011719 5.753906 -24.273438 5.347656 -24.273438 L 1.839844 -24.273438 C 1.472656 -24.273438 1.261719 -24.011719 1.261719 -23.589844 L 1.261719 -0.667969 C 1.261719 -0.246094 1.4375 0 1.839844 0 L 5.347656 0 C 5.753906 0 5.929688 -0.246094 5.929688 -0.667969 Z M 5.929688 -23.589844 " />
        </g>
      </g>
    </g>
    <g fill="#64ffda" fillOpacity="1">
      <g transform="translate(181.156947, 200.708826)">
        <g>
          <path d="M 11.699219 -20.011719 C 11.996094 -20.011719 12.207031 -20.222656 12.207031 -20.644531 L 12.207031 -23.640625 C 12.207031 -24.0625 11.996094 -24.273438 11.699219 -24.273438 L 0.492188 -24.273438 C 0.191406 -24.273438 -0.015625 -24.046875 -0.015625 -23.625 L -0.015625 -20.644531 C -0.015625 -20.222656 0.191406 -20.011719 0.492188 -20.011719 L 3.753906 -20.011719 L 3.753906 -0.613281 C 3.753906 -0.210938 3.964844 0 4.261719 0 L 7.925781 0 C 8.207031 0 8.4375 -0.210938 8.4375 -0.613281 L 8.4375 -20.011719 Z M 11.699219 -20.011719 " />
        </g>
      </g>
    </g>
    <g fill="#64ffda" fillOpacity="1">
      <g transform="translate(211.826337, 200.708826)">
        <g />
      </g>
    </g>
    <g fill="#64ffda" fillOpacity="1">
      <g transform="translate(238.463457, 200.708826)">
        <g>
          <path d="M 8.820312 -17.238281 C 8.820312 -16.75 8.890625 -16.574219 9.332031 -16.574219 L 12.46875 -16.574219 C 13.03125 -16.574219 13.152344 -16.75 13.152344 -17.238281 L 13.152344 -18.238281 C 13.152344 -22.117188 10.558594 -24.640625 6.925781 -24.640625 C 4.472656 -24.640625 0.703125 -22.90625 0.703125 -18.34375 C 0.703125 -14.328125 2.351562 -13.171875 6.015625 -10.191406 C 8.171875 -8.4375 9.085938 -7.648438 9.085938 -5.945312 C 9.085938 -4.664062 8.191406 -3.859375 7.085938 -3.859375 C 6.191406 -3.859375 5.085938 -4.417969 5.085938 -6.171875 L 5.085938 -6.960938 C 5.085938 -7.488281 4.980469 -7.683594 4.488281 -7.683594 L 1.296875 -7.683594 C 0.808594 -7.683594 0.703125 -7.4375 0.703125 -6.839844 L 0.703125 -5.910156 C 0.703125 -2.613281 2.824219 0.386719 7.050781 0.386719 C 10.925781 0.386719 13.523438 -2.421875 13.523438 -5.699219 C 13.523438 -10.136719 10.910156 -12.050781 7.628906 -14.59375 C 6.066406 -15.800781 5.035156 -16.554688 5.035156 -18.222656 C 5.035156 -19.816406 6.050781 -20.433594 6.925781 -20.433594 C 8.191406 -20.433594 8.820312 -19.433594 8.820312 -18.222656 Z M 8.820312 -17.238281 " />
        </g>
      </g>
    </g>
    <g fill="#64ffda" fillOpacity="1">
      <g transform="translate(270.991198, 200.708826)">
        <g>
          <path d="M 10.382812 -24.273438 C 9.980469 -24.273438 9.785156 -23.957031 9.785156 -23.589844 L 9.785156 -14.539062 L 5.929688 -14.539062 L 5.929688 -23.589844 C 5.929688 -24.011719 5.753906 -24.273438 5.347656 -24.273438 L 1.839844 -24.273438 C 1.457031 -24.273438 1.261719 -24.011719 1.261719 -23.589844 L 1.261719 -0.667969 C 1.261719 -0.246094 1.4375 0 1.839844 0 L 5.347656 0 C 5.753906 0 5.929688 -0.246094 5.929688 -0.667969 L 5.929688 -10.3125 L 9.785156 -10.3125 L 9.785156 -0.667969 C 9.785156 -0.246094 9.996094 0 10.382812 0 L 13.820312 0 C 14.277344 0 14.453125 -0.246094 14.453125 -0.667969 L 14.453125 -23.605469 C 14.453125 -24.113281 14.171875 -24.273438 13.871094 -24.273438 Z M 10.382812 -24.273438 " />
        </g>
      </g>
    </g>
    <g fill="#64ffda" fillOpacity="1">
      <g transform="translate(305.219526, 200.708826)">
        <g>
          <path d="M 5.789062 -18.308594 C 5.789062 -19.414062 6.472656 -20.414062 7.664062 -20.414062 C 8.839844 -20.414062 9.523438 -19.414062 9.523438 -18.308594 L 9.523438 -9.875 L 5.789062 -9.875 Z M 14.207031 -18.574219 C 14.207031 -21.941406 11.15625 -24.660156 7.648438 -24.660156 C 4.140625 -24.660156 1.105469 -21.941406 1.105469 -18.574219 L 1.105469 -0.683594 C 1.105469 -0.316406 1.296875 0 1.5625 0 L 5.261719 0 C 5.683594 0 5.789062 -0.296875 5.789062 -0.683594 L 5.789062 -5.789062 L 9.523438 -5.789062 L 9.523438 -0.703125 C 9.523438 -0.332031 9.734375 0 10.085938 0 L 13.644531 0 C 14.046875 0 14.207031 -0.351562 14.207031 -0.703125 Z M 14.207031 -18.574219 " />
        </g>
      </g>
    </g>
    <g fill="#64ffda" fillOpacity="1">
      <g transform="translate(339.027095, 200.708826)">
        <g>
          <path d="M 10.382812 -24.273438 C 9.980469 -24.273438 9.785156 -23.957031 9.785156 -23.589844 L 9.785156 -14.539062 L 5.929688 -14.539062 L 5.929688 -23.589844 C 5.929688 -24.011719 5.753906 -24.273438 5.347656 -24.273438 L 1.839844 -24.273438 C 1.457031 -24.273438 1.261719 -24.011719 1.261719 -23.589844 L 1.261719 -0.667969 C 1.261719 -0.246094 1.4375 0 1.839844 0 L 5.347656 0 C 5.753906 0 5.929688 -0.246094 5.929688 -0.667969 L 5.929688 -10.3125 L 9.785156 -10.3125 L 9.785156 -0.667969 C 9.785156 -0.246094 9.996094 0 10.382812 0 L 13.820312 0 C 14.277344 0 14.453125 -0.246094 14.453125 -0.667969 L 14.453125 -23.605469 C 14.453125 -24.113281 14.171875 -24.273438 13.871094 -24.273438 Z M 10.382812 -24.273438 " />
        </g>
      </g>
    </g>
    <path
      strokeLinecap="butt"
      transform="matrix(2.995057, 0, 0, 2.993934, 0.446757, 148.807533)"
      fill="none"
      strokeLinejoin="miter"
      d="M -0.000482409 0.000354157 L 124.955389 0.000354157 "
      stroke="#64ffda"
      strokeWidth="1"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
    <path
      strokeLinecap="butt"
      transform="matrix(2.995057, 0, 0, 2.993934, 0.446757, 226.279483)"
      fill="none"
      strokeLinejoin="miter"
      d="M -0.000482409 0.00059028 L 124.955389 0.00059028 "
      stroke="#64ffda"
      strokeWidth="1"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
  </svg>
);

export default IconLoader;
